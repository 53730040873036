<template>
  <div>
    <list-assign-roles/>
  </div>
</template>

<script>
import ListAssignRoles from "@core/layouts/components/admin-components/ListAssignRoles";

export default {
  name: "AssignRoleUser",
  components: {
    ListAssignRoles
  }
}
</script>

<style scoped>

</style>